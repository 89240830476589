.page-banner {
	min-height: 500px;

	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	color: #fff;
	--gs--headline--color: #fff;
	--gs--link-underline--color: #fff;

	.page-banner--inner {
		padding-top: 4rem;
		padding-bottom: 4rem;
		@include max-width-content;

		.title-and-logo {
			display: grid;
			grid-template-columns: 1fr 370px;
			gap: 8rem;

			figure {
				//remove browser default margin
				margin: 0;
			}

			@include desktop-small {
				gap: 4rem;
				grid-template-columns: 1fr 260px;
			}

			@include lg {
				grid-template-columns: 1fr;
				gap: 3rem;

                .microsite-logo {
                    justify-self: center;
                    figure {
                        max-width: 150px;
                        display:none;
                    }
                }

				h1, .h1 {
					margin-bottom: 0;
				}
			}
		}
	}
	&.interactive {
		position: relative;
		min-height: unset;

		.page-banner--inner {
			position: absolute;
			padding-left: 0;
			padding-right: 0;
			top: 0;
			left: calc((100% - (#{$max-content-width} - (#{gs-space(side-space)} * 2))) / 2);
		
			@include max-content {
				left: gs-space(side-space);
			}
		}
	}
	&.microsite {
	    min-height: 100px;
    }
}
