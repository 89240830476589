.frame-type-gesis-web-big-newsbox {
	background-color: gs-color(gs-default-grey);
}

.gesis-big-newsbox {
	background-color: #fff;
	margin: 0 auto;
	max-width: calc(#{$max-content-width} - (#{gs-space(side-space)} * 2) + (2.6rem * 2));
	padding: 1.5rem 2.6rem 5rem;
	border-radius: 0.5rem;

	@include lg {
		padding: 1.5rem gs-space(side-space) 3rem;
	}

	@media (max-width: 1800px) {
		margin: 0 20px;
		max-width: 100%;
	}

	.gesis-big-newsbox--inner {
		.upper-newsbox-section {
			padding-bottom: 5rem;
			margin-bottom: 5rem;
			border-bottom: 1px solid gs-color(gs-accent-grey);

			.news {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				gap: 3rem 2rem;

				@include lg {
					gap: 3rem 1.5rem;
				}

				@include md {
					grid-template-columns: 1fr;
				}
			}
		}
	}
}

.gesis-big-newsbox--tile {
	.news-content {
		display: flex;
		flex-direction: column;
		height: 100%;

		// Remove margin from last child (wich is an p with the readmore button inside or the Button itself)
		> :last-child {
			margin-top: auto;
			margin-bottom: 0;
		}

		.news-date {
			background-color: gs-color(gs-default-grey);
			color: gs-color(gs-darkblue);
			padding: 0.4rem 1rem;
			margin-bottom: 1.1rem;

			time {
				font-weight: bold;
				margin-right: 0.8rem;
			}
		}
	}

	&.top-news {
		display: grid;
		grid-template-columns: 1fr 2fr;
		gap: 2rem;

		.news-thumbnail {
			a {
				//remove default underline
				box-shadow: none;
			}

			img {
				width: 100%;
			}
		}

		.news-date {
			background-color: transparent;
			padding: 0;
		}

		@include md {
			grid-template-columns: 1fr;
		}
	}
}
