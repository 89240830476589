.gesis-cta-social-media-strip {
	background: gs-gradient(berry-darkblue-to-lightblue);

	.gesis-cta-social-media-strip--inner {
		@include max-width-content;
		color: #fff;
		padding-top: 3.5rem;
		padding-bottom: 3.5rem;

		h2,
		.h2 {
			color: #fff;
			margin-bottom: 1.9rem;
		}

		.strip-text {
			> :last-child,
			// Needed for implementation in typo3
			.ce-bodytext:last-of-type, 
			.ce-bodytext:last-of-type > :last-child {
				margin-bottom: 0;
			}
		}

		.strip-icons {
			display: flex;
			justify-content: flex-end;
			gap: 1.8rem;

			a {
				// Remove default link underline
				box-shadow: none;

				i {
					font-size: 7rem;

					@include desktop-small {
						font-size: 5rem;
					}

					@include xl {
						font-size: 3rem;
					}
				}

				&:hover {
					opacity: 0.8;
				}
			}
		}

		@include desktop-small {
			>.row {
				display: grid;
				grid-template-columns: 1fr auto;
				gap: gs-space(grid-gutter-width);
			}
		}

		@include md {
			>.row {
				grid-template-columns: 1fr;
			}

			.strip-icons {
				justify-content: flex-start;
			}
		}
	}
}
