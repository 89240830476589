header {
	a {
		box-shadow: none;
		&:hover {
			box-shadow: none;
		}
	}
	&.page-header,
	&.microsite-header {
		position: relative;
		z-index: 50;
		background-color: #fff;

		.logo-wrapper {
			margin-right: 50px;

			img {
				width: gs-var(logo-width);
				max-width: 100%;
			}

			@include mobile {
				margin-right: 70px;
			}
		}

		.logo-and-navigations--inner {
			@include max-width-content;
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
		}

		@include mobile {
			position: static;
		}
	}

	&.page-header {
		.logo-and-navigations {
			position: relative;

			.logo-and-navigations--inner {
				padding-top: 20px;
				padding-bottom: 30px;

				.navigations-wrapper {
					display: flex;
					flex-direction: column;
					align-items: flex-end;

					nav.gs_topnav {
						margin-bottom: 40px;
					}

					@include mobile {
						display: block;
					}
				}
			}

			@include mobile {
				position: static;
			}
		}
	}

	&.microsite-header {
		.logo-and-navigations--inner {
			padding-top: 50px;
			padding-bottom: 30px;

			.navigations-wrapper {
				/**
				* Added mall space to move the menu a little higher because the menu should be align with the text in the logo
				*
				* @author Julian Kohl
				*
				*/
				margin-bottom: 1%;
			}
		}

		.microsite-logo-and-title {
			background-image: url("/images/demo_images/dummy_header_bg_unterseiten.jpg");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: left center;
			.microsite-logo-and-title--inner {
				--gs--headline--color: #fff;
				@include max-width-content;
				padding-top: 4rem;
				padding-bottom: 4rem;
				min-height: 20rem;

				nav.gs_rootline ol {
					padding: 0;
				}
			}
		}

		.main-nav-row {
			background-color: gs-color(gs-darkblue--14);

			.main-nav-row--inner {
				@include max-width-content;
				padding-top: 1.5rem;
				padding-bottom: 1.5rem;
			}
		}
	}
}
