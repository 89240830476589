.accordion,
details {
	//ACHTUNG: Genaue Werte könnten sich nochmal ändern
	margin-bottom: gs-space(third);
	> button,
	summary {
		/* Reset browser defaults */
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		border: unset;
		font-size: gs-font-size(text--default);
		font-family: inherit;
		text-align: left;

		&::-webkit-details-marker {
			display: none;
		}
		/* Reset end */

		/* Reset default button style */
		border-radius: 0;
		&::after {
			content: "";
		}
		/* Reset end */

		@include arrow-right-before($arrow-font-size: 1rem, $space-between: 0.7rem);
		width: 100%;
		background-color: gs-color(gs-default-grey);
		color: gs-color(gs-berry);
		border-top-left-radius: gs-var(default-border-radius);
		border-top-right-radius: gs-var(default-border-radius);
		font-weight: bold;
		padding: 1rem 1.5rem;
		cursor: pointer;
		
		&[aria-expanded="true"],
		&:hover {
			background-color: gs-color(gs-berry);
			color: #fff;
		}
		
		&[aria-expanded="true"] {
			&::before {
				content: "\e91a";
			}
		}
		
		@include sm {
			@include arrow-right-before($arrow-font-size: 0.8rem, $space-between: 0.6rem);
			padding: .8rem 1rem;
		}
	}

	> div {
		background-color: gs-color(gs-default-grey);
		padding: 2.5rem calc(1.5rem + 0.7rem + 1rem);
		margin-top: gs-space(third);

		&[hidden] {
			margin: 0;
			padding: 0;
		}

		@include md {
			padding: 2rem 1.5rem;
		}

		@include sm {
			padding: 1.8rem 1rem;
		}
	}
}
