.gesis-contact-button {
	position: fixed;
	right: 1.5rem;
	top: 30vh;
	line-height: 1;

	// z-index set to prevent an unwanted element from overlapping the button
	z-index: 51;

	a {
		line-height: 1;

		// Remove default link underline
		box-shadow: none !important;

		&:hover {
			opacity: 0.8;
		}
	}

	i {
		font-size: 3.9rem;
		color: gs-color(gs-berry);
		position: relative;

		/**
		* I have created a pseudo element here to give the icon a white background. 
		* This is necessary because icons in an icon font can only have one color. Therefore the person in the icon is cut out.
		* 
		*
		* @author Julian Kohl
		*
		*/

		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 3.7rem;
			height: 3.7rem;
			background-color: #fff;
			border-radius: calc(3.7rem * 2);
			z-index: -1;
		}
	}
}
