.gs-quote-text-outline {
    p {
        border-color: var(--gs--color--gs-lightblue);
        border-radius: var(--bs-border-radius-xl);
        border-style: solid;
        border-width: 2px;
        padding: 2rem;
        margin: 3rem 2rem;
        font-size: 1.3rem;
        font-style: italic;
    }

    &-big{
        p {
            border-color: var(--gs--color--gs-lightblue);
            border-radius: var(--bs-border-radius-xl);
            border-style: solid;
            border-width: 2px;
            padding: 2rem;
            margin: 3rem 2rem;
            font-size: 1rem;
            font-weight: 200;
            font-style: italic;
        }
    }
}
.gs-quote-text-filled {
    p {
        background-color: var(--gs--color--gs-darkblue);
        border-radius: var(--bs-border-radius-xl);
        color: white;
        padding: 2rem;
        margin: 3rem 2rem;
        font-size: 1.3rem;
        font-style: italic;
    }
    &-big{
        p {
            background-color: var(--gs--color--gs-darkblue);
            border-radius: var(--bs-border-radius-xl);
            color:  white;
            padding: 2rem;
            margin: 3rem 2rem;
            font-size: 1rem;
            font-weight: 200;
            font-style: italic;
        }
    }
}
