.slider {
	position: relative;
	margin: 0 !important;

	$size: 1.3rem;
	.slick-dots {
		position: absolute;
		bottom: 12.5rem;
		width: 100%;
		list-style: none;
		text-align: center;
		margin: 0;
		padding: 0;

		li {
			display: inline-block;

			button {
				background: gs-color(gs-lightblue);
				border-color: gs-color(gs-lightblue);
				display: inline-block;
				color: transparent;
				width: $size;
				height: $size;
				padding: 0;

				&::after,
				&::before {
					display: none;
				}
			}

			&:not(:last-of-type) {
				margin-right: 0.5rem;
			}

			&.slick-active {
				button {
					background: white;
				}
			}
		}

		@include md {
			bottom: 4.5rem;
		}

		@include sm {
			bottom: 2.5rem;
		}
	}

	.slick-slide {
		position: relative;

		img {
			width: 100%;
			height: auto;
			margin: 0 !important;
		}
	}

	.slide-content {
		position: absolute;
		width: 55%;
		// width: 50%;
		top: calc(50% - 7rem);
		// top: calc(50% - 10rem);
		transform: translateY(-50%);
		@include max-width-content;
		padding-right: 0;
		font-size: 3rem;
		// font-size: 3.1rem;
		line-height: 1.2em;
		color: white;

		a.btn {
			@include arrow-right-after(1.2rem, inherit, 1rem);
			background: white;
			border-radius: 100vw;
			border: solid 3px gs-color(gs-pink);
			color: gs-color(gs-pink);
			font-weight: bold;
			font-size: 1.8rem;
			display: inline-block;
			padding: 0.5rem 2rem;
			box-shadow: none !important;

			&:hover {
				color: white;
				background: gs-color(gs-pink);
			}
		}

		@include desktop-medium {
			font-size: 2rem;

			a.btn {
				font-size: 1.4rem;

				&:after {
					font-size: 1.1rem;
				}
			}
		}

		@include desktop-small {
			width: 70%;
			// top: calc(50% - 7rem);
		}

		@include lg {
			width: 80%;
			font-size: 1.5rem;

			a.btn {
				font-size: 1.1rem;

				&::after {
					font-size: 1rem;
				}
			}
		}

		@include md {
			top: calc(50% - 2rem);
		}

		@include sm {
			top: calc(50% - 1rem);
			font-size: 1.3rem;
			width: 100%;
			padding-right: gs-space(side-space);

			a.btn {
				font-size: 1rem;
				padding: 0.5rem 1rem;

				&:after {
					font-size: 0.8rem;
					line-height: 1;
					margin-left: .5rem;
				}
			}
		}
	}

	&.testimonial {
		background-color: red;
		background: url("./img/icons/quote_bottom.svg") bottom 2rem left 2rem no-repeat, url("./img/icons/quote_top.svg") top 2rem right 2rem no-repeat, gs-gradient(testimonial) no-repeat;
		background-size: 512px, 512px, 200%;

		img {
			position: absolute;
			bottom: 0;
			right: 1rem;
			max-width: 48%;
			float: right;
		}

		.slick-slide {
			aspect-ratio: 1.7;
		}

		.slide-content {
			top: 50% !important;
			font-size: 3rem;
		}

        .testimonial-name {
			font-size: 1.5rem;
			font-weight: bold;
			color: #ccc;
		}

	}

	&.slider-frontpage {
		.slick-slide {
			min-height: 820px;

			img {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}

			@include desktop-small {
				min-height: 800px;
			}

			@include lg {
				min-height: 700px;
			}

			@include md {
				min-height: 600px;
			}

			@include sm {
				min-height: 400px;
			}
		}
	}
}

// this puts the news box on the frontpage partially up into the slider
.frame-layout-200 + * {
	margin-bottom: calc((11rem - 1px) * -1); //Without calc there is wierd behavoir in Chrome wich causes the Newsbox to slighly be in next section. Its just 1px or lesss but this should prevent the behavoir
	& > *:first-child {
		transform: translateY(-11rem);
	}

	@include md {
		margin-bottom: calc((3rem - 1px) * -1);

		& > *:first-child {
			transform: translateY(-3rem);
		}
	}
	@include sm {
		margin-bottom: calc((1rem - 1px) * -1);

		& > *:first-child {
			transform: translateY(-1rem);
		}
	}
}
