@import "./headings";

p {
	margin-bottom: 2.3rem;

	&.text-no-space-bottom {
		margin-bottom: 0;
	}

	@include xl {
		margin-bottom: 1.5rem;
	}

	@include md {
		margin-bottom: 1.2rem;
	}
}
