/**
* Ich habe die Link und Button regeln mal auf <main> "gescoped" damit nur dort auch die links entsprechend aussehen. 
* Ich habe das gemacht damit im Menü, Footer o.ä. nicht auch diese Styles bekommen und man die dann nochmal überschreiben muss
*
* @author Julian Kohl
*
*/

.main-nav-row {
  button:after{
    content:none;
  }
  button:hover{
      background: none;
      color: var(--gs--color--gs-darkblue);
  }
}

.close-button {
  padding: 0;
  &:after {
    margin: 0;
  }
}

main,.page-banner {
	a {
		color: inherit;
		@include underline-link;

		&.gesis-link--arrow-before {
			@include arrow-right-before($arrow-font-size: 1em, $arrow-color: gs-var(link-with-icon--color));
			display: inline-flex;

			// Remove default underline
			box-shadow: none;

			&:hover {
				color: gs-var(link-with-icon--hover-color);

				&:before {
					color: gs-var(link-with-icon--hover-color);
				}
			}
		}

		&.download-calendar-file {
			display: inline-flex;
			align-items: baseline;

			&:before {
				content: $gesis-icon-calendar-arr-down;
				font-family: $gesis-icons-font;
				color: gs-color(gs-pink);
				margin-right: 0.5em;
				line-height: 1;
			}

			// Remove default link undline when not hovering
			box-shadow: none;

			&:hover {
				color: gs-var(link-with-icon--hover-color);

				&:before {
					color: gs-var(link-with-icon--hover-color);
				}
			}
		}

		&.round-icon-before-link {
			display: inline-flex;
			align-items: center;

			&:before {
				font-family: $gesis-icons-font;
				color: gs-color(gs-darkblue);
				margin-right: 0.5rem;
				line-height: 1;
				font-size: 1.3rem;
			}

			// Remove default link undline when not hovering
			box-shadow: none;

			&:hover {
				color: gs-color(gs-darkblue);
			}

			&.icon-tel:before {
				content: $gesis-icon-phone-circle;
			}
			&.icon-mail:before {
				content: $gesis-icon-mail-circle;
			}
			&.icon-vcard:before {
				content: $gesis-icon-vcard-circle;
			}
			&.icon-fax:before {
				content: $gesis-icon-fax-circle;
			}
			&.icon-location:before {
				content: $gesis-icon-location-circle;
			}
		}

		&.gesis-button--functional {
			// Remove default link undline
			box-shadow: none;

			@include gesis-button($button-text-size: gs-font-size(text--functional-button));
			color: #fff;
			background-color: gs-color(gs-lightblue);
			border-color: gs-color(gs-lightblue); // Set Border color here tio retain button dimensions
			display: inline-flex;


			&:after {
				color: #fff;
			}

			&:hover {
				//background-color: gs-color(gs-lightblue--14);
				background-color: #fff;
				color: gs-color(gs-lightblue);

				&:after {
					color: gs-color(gs-lightblue);
				}
			}
		}
	}
	button {
		/* Reset browser defaults */
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		border: unset;
		text-align: left;
		font-family: inherit;
		font-size: gs-font-size(text--default);
		/* Reset end */
	}

	button,
	a.gesis-button--default, input[type="button"] {
		// Remove default link undline
		box-shadow: none;

		@include gesis-button();
		display: inline-flex;
	}
	button.cta,
	a {
		&.gesis-button--cta {
			// Remove default link undline
			box-shadow: none;

			@include gesis-button($button-text-size: gs-font-size(text--cta-button));
			display: inline-flex;
            i {
                margin-right: 0.5em;
            }
            &.--gs--color--gs-lightblue{
                background-color: gs-color(gs-lightblue);
                border-color: gs-color(gs-lightblue); // Set Border color here tio retain button dimensions
                color: #fff;
                &:after {
                    color: #fff;
                }
                &:hover {
                    //background-color: gs-color(gs-lightblue--14);
                    background-color: #fff;
                    color: gs-color(gs-lightblue);

                    &:after {
                        color: gs-color(gs-lightblue);
                    }
                }
            }
		}
	}
}

.btn-check:checked+.btn, :not(.btn-check)+.btn:active, .btn:first-child:active, .btn.active, .btn.show {

    color: black;
}
