.badge {
	//font-size: 1.78rem;
	font-size: 1rem;
	border-radius: 999px;
	padding: 0.5rem 1rem;

	@each $state in map-keys($theme-colors) {
		&.bg-#{$state} {
			background: gs-color(#{map-get($bootstrapMapping, $state)}) !important;
		}
	}
}