// see https://getbootstrap.com/docs/5.3/customize/sass/
// see https://getbootstrap.com/docs/5.3/customize/optimize/

// Option A: Include all of Bootstrap
// @import '../../node_modules/bootstrap/scss/bootstrap';

// Option B: cherry-picked import for smaller file size
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
//TODO check if needed. If needed check with color fits the best $primary: #003c78;
$body-color: #000;

// True Base Font size is set in _overwrite_defaults.scss
// When font-size is only set on body-tag with eg. 1.2rem then 1rem is not based on this 1.2rem set on body-tag.
// This leads to some inconsitent font-sizes. Therefore I also set the font-size in html, now 1rem is always based on the value set on html
$font-size-base: 1rem;

$line-height-base: 1.6;

$enable-rounded: true;
$enable-negative-margins: true;

$font-family-sans-serif: $default-font;
// diabled till var is filled $font-family-monospace: $gs_font_code;

$h1-font-size: gs-font-size(heading--primary);
$h2-font-size: gs-font-size(heading--secondary);
$h3-font-size: gs-font-size(heading--tertiary);
$h4-font-size: gs-font-size(text--default);
$h5-font-size: gs-font-size(text--default);
$headings-font-weight: bold; // h1-h3 have a font-weight of medium set in _headings.scss

// see https://stackoverflow.com/a/68303393/923560
// see https://stackoverflow.com/a/67958494/923560
$min-contrast-ratio: 0;

$enable-cssgrid: true;

/* 
TODO: Schauen ob auskommentieren reicht oder man das füllen sollte
$spacer: 1rem;

$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
); */

// removing xxl size
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
);

/* $grid-gutter-width: 2.6rem;
$grid-gutter-height: 2.6rem; */
$grid-gutter-width: gs-space(grid-gutter-width);
$grid-gutter-height: gs-space(grid-gutter-height);

/**
* Set horizontal gutter height for Gesis cols
*
*/

.frame-type-gesis-web-2col, .frame-type-gesis-web-3col, .frame-type-gesis-web-2col-1third2thirds, .frame-type-gesis-web-2col-2thirds-with-menu, .is-newsbox .gesis-contentbox--content {
	>.row {
		--bs-gutter-y: #{gs-space(grid-gutter-height)};
	}
}

// Variable set here because otherwise there is an error as the variable normally expects an scss variable like so: $card-group-margin: $grid-gutter-width * .5)
$card-group-margin: calc(#{gs-space(grid-gutter-width)} * .5);

// removing xxl size
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
);

$alert-padding-x             : 2rem;
$alert-padding-y             : 2.1rem;
//$alert-margin-bottom         : 1rem;
$alert-border-radius         : 1.7rem;
//$alert-link-font-weight      : $font-weight-bold;
//$alert-border-width          : $border-width;
//$alert-bg-scale              : -80%;
//$alert-border-scale          : -70%;
//$alert-color-scale           : 40%;
//$alert-dismissible-padding-r : $alert-padding-x * 3; // 3x covers width of x plus default

// 3. Include remainder of required Botstrap stylesheets
@import "../../node_modules/bootstrap/scss/variables";

// 4. Include any default map overrides here
// diabled till var is filled $theme-colors: map-merge($theme-colors, $gesis-colors);

// 5. Include remainder of required parts
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";

// 6. Optionally include any other parts as needed
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
// @import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
// @import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
// @import "../../node_modules/bootstrap/scss/nav";
// @import "../../node_modules/bootstrap/scss/navbar";
// @import "../../node_modules/bootstrap/scss/card";
// @import "../../node_modules/bootstrap/scss/accordion";
// @import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/alert";
// @import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/list-group";
// @import "../../node_modules/bootstrap/scss/close";
// @import "../../node_modules/bootstrap/scss/toasts";
// @import "../../node_modules/bootstrap/scss/modal";
// @import "../../node_modules/bootstrap/scss/tooltip";
// @import "../../node_modules/bootstrap/scss/popover";
// @import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/spinners";
// @import "../../node_modules/bootstrap/scss/offcanvas";
// @import "../../node_modules/bootstrap/scss/placeholders";

@import "../../node_modules/bootstrap/scss/helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../../node_modules/bootstrap/scss/utilities/api";

$bootstrap-icons-font-dir: "./font/bootstrap-icons";
@import '../../node_modules/bootstrap-icons/font/bootstrap-icons.scss';


// Bootstrap 5's reboot style  has a silly rule to set
// anything with attribute [hidden]
// to "display: none !important;"
// this breaks search-in-invisible-content with
// 'hidden="until-found"', e.g. "search within collapsed .accordion / details"
// therefore overrule with with our own reset
// see https://github.com/twbs/bootstrap/issues/9237
// see https://developer.chrome.com/articles/hidden-until-found/
// see https://developer.mozilla.org/en-US/docs/Web/API/Element/beforematch_event
// see https://developer.mozilla.org/en-US/docs/Web/CSS/revert
// see https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/hidden
// see https://developer.mozilla.org/en-US/docs/Web/CSS/display
//
// taking support for "content-visibility: auto" as a proxy
// for checking on support for 'hidden="until-found"'
// see https://developer.mozilla.org/en-US/docs/Web/CSS/content-visibility
// see https://developer.mozilla.org/en-US/docs/Web/CSS/@supports
@supports (content-visibility: auto) {
	[hidden="until-found"] {
		display: revert !important;
	}
}
