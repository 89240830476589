nav.sidebar-nav {
	.sidebar-nav--title {
		color: gs-color(gs-darkblue);
		margin-bottom: 0;
	}

	ul {
		list-style: none;
		padding-left: 0;
		margin-top: 15px;
		-webkit-hyphens: auto;
		-ms-hyphens: auto;
		hyphens: auto;

		li {
			a {
				display: block;
				border-top: 1px solid gs-color(gs-darkblue);
				padding: 0.3em 0.2em;
				@include arrow-right-before($arrow-font-size: 0.8em, $arrow-color: gs-color(gs-pink));

				// Remove default link undline
				box-shadow: none !important;

				&:hover {
					background-color: gs-color(gs-accent-grey);
					color: #000;
				}
			}
		}
	}
}
