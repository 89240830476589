.gris-staff-directory {
	.tabs {
		[role="tablist"] {
			// padding-left: 8rem;
			// padding-right: 8rem;
			margin-bottom: 0.2rem;
			position: relative;

			.gris-staff-view {
				position: absolute;
				top: 50%;
				right: 1.3rem;
				transform: translateY(-50%);

				a {
					line-height: 1; //Added line-height: 1 here to prevent link from beeing taller then the icon

					// Remove default link underline
					box-shadow: none;

					i {
						color: #fff;
						font-size: 2.5rem;
					}

					&[aria-pressed="true"],
					&:hover {
						i {
							color: gs-color(gs-berry);
						}
					}

					&:last-of-type {
						margin-left: 1rem;
					}
				}
			}
		}

		[role="tabpanel"] {
			background-color: gs-color(gs-berry--14);
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;

			&:not([hidden]) {
				padding: 1.5rem 2.5rem;

				@include xl {
					padding: 1.5rem;
				}
			}

			nav {
				ul.gs_pagebrowser {
					display: block;
					text-align: center;

					li {
						display: inline-block;
						padding: 0;
						border-radius: 0;
						background-color: transparent;
						margin-bottom: 0.5rem;

						&:not(:last-of-type) {
							margin-right: 0.5rem;
						}

						a {
							@include pagination-dot;
							background-color: #fff;
							color: gs-color(gs-berry);

							&[aria-current="page"],
							&:hover {
								background-color: gs-color(gs-berry);
								color: #fff;
							}
						}
					}
				}
			}

			//Row with Buttons to select Department
			>.row {
				@include md {
					gap: 1rem;
				}
			}
		}
	}
	.gris-staff {
		background-color: gs-color(gs-default-grey);
		padding: 2.5rem;
		margin-top: 0.2rem;

		@include xl {
			padding: 2rem 1.5rem;
		}
	}
}

// This wrapper is used to make the grid for the Contact Cards
.gris-staff {
	display: grid;
	grid-template-columns: 1fr 1fr;
	// This calc is needed to match this grid gap to the one from Bootstrap
	gap: calc((#{gs-space(grid-gutter-width)} * 0.5) * 2);

	hr {
		grid-column: 1 / -1;
	}


	@include lg {
		grid-template-columns: 1fr;
	}

	&.without-image {
		grid-template-columns: repeat(3, 1fr);

		@include lg {
			grid-template-columns: 1fr 1fr;
		}

		@include md {
			grid-template-columns: 1fr;
		}
	}
}

/**
* Styling for persons detail page
*
* @author Julian Kohl
*	
*/

.person-details-page {
	.gesis-contact-card {
		margin-bottom: 3.5rem;
	}

	section.person-details {
		position: relative;
		padding-top: 2rem;
		padding-bottom: 4rem;
		margin-bottom: 2rem;
		max-width: 70%;

		&:first-of-type {
			padding-top: 0;
		}

		.back-to-top-button {
			bottom: 0;
			left: 0;
		}

		&.publications {
			padding: 0;
		}
	}

	.resource-group {
		padding-bottom: 3rem;
		position: relative;

		&:last-of-type {
			padding-bottom: 0;
		}

		.resource {
			padding-bottom: 1.5rem;
			margin-bottom: 3.5rem;
			position: relative;
		}
	}

	hr {
		margin: 4rem 0;
	}
}