// Imported in _variables.scss
$root-font-size : 1.2rem;

$gs-font-sizes: (
	heading--primary   				: 3.1rem,
	heading--secondary 				: 2.1rem,
	heading--tertiary  				: 1.5rem,
	text--default      				: 1rem,
	text--cta-button   				: 1.5rem,
	text--functional-button   : 1.5rem,
);

:root {
	@each $variableName, $variableValue in $gs-font-sizes {
		--gs--font-size--#{$variableName}: #{$variableValue};
	}

	@media (max-width: #{$xl}) {
		--gs--font-size--heading--primary: 2.4rem;
		--gs--font-size--heading--secondary: 1.7rem;		
		--gs--font-size--heading--tertiary: 1.2rem;		
	}
	
	@media (max-width: #{$md}) {
		--gs--font-size--heading--primary: 2.2rem;
	}
}

@function gs-font-size($variableName) {
	@return var(--gs--font-size--#{$variableName});
}
