@import "variables";
@import "utils/functions";
@import "utils/mixins";
@import "utils/utility-classes";
@import "overwrite-defaults";
@import "fonts";

@import "bootstrap-imports";
@import "lightbox";

@import "text";
@import "links-and-buttons";
@import "boxes";
@import "quotes";

@import "header";
@import "footer";
@import "navigations";

@import '../../node_modules/highlight.js/scss/base16/default-dark.scss';

@import "widgets";
@import "guides";

@import "lists";
@import "rte-styles";

@import "eurobarometer-list-plugin";
@import "microsites/volltext-archiv-hsr";
@import "font-viewer";

.news-img-wrap {
  margin-bottom: 30px;
}
  
.has-backdrop {
	position: relative;
    z-index: 10;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: gs-color(gs-darkblue);
		opacity: .7;
		z-index: 30;

        /*
		@include mobile {
			display: none;
		};
        */
	}
}

body {
	position: relative;
}

/* Stylings for this implementation only (For dummy purpose) */

.newsbox-demo-wrapper {
	display: grid;
	grid-template-columns: 1fr;
	background-color: gs-color(gs-default-grey);

	img, .gesis-big-newsbox {
		grid-column: 1 / -1;
		grid-row: 1;
	}

	.gesis-big-newsbox {
		margin-top: 27%;
		height: max-content;
		z-index: 1;
	}
}
