footer {
    a {
        box-shadow: none;
        &:hover {
            box-shadow: none;
        }
    }
	--gs--link-underline--color: #fff;
	--gs--link-underline--hover-color: #{rgba(#fff, 0.5)};

	&.page-footer {
		background-color: gs-color(gs-darkblue);
		border-top: 10px solid gs-color(gs-pink);
		padding: 50px 0 100px;
		color: #fff;

		.page-footer--inner {
			@include max-width-content;

			/**
      * Ich habe hier ein css grid mit grid-areas verwendet weil man damit man auf verschiedene wrapper verzichten kann. 
      * Außerdem macht es das umpositionieren für kleinere Screens etwas einfacher und freier. 
      * Denn alle elemnte können bequem über die grid-areas einem Platz zugewiesen werden. Und Mobile muss man dann nur die Definition der Grid Areas ändern
      *
      * 
      */
			display: grid;
			grid-template-areas:
				"contact-mannheim contact-koeln social-media"
				"contact-mannheim contact-koeln leibniz-logo"
				"contact-mannheim contact-koeln footer-nav";

			.contact-info {
				// Noch nicht final geklärt ob telefonnummer überhaupt einen link bekommen und wie dieser aussehen soll
				a {
					text-decoration: none;
					color: inherit;
					@include underline-link;
				}
				// --- end comment --

				&.standort-mannheim {
					grid-area: contact-mannheim;
				}

				&.standort-koeln {
					grid-area: contact-koeln;
				}
			}

			.social-media-icons,
			.leibniz-logo-wrapper,
			nav.footer {
				justify-self: end;
			}

			.social-media-icons {
				grid-area: social-media;
				margin-bottom: 20px;

				a {
					color: inherit;
					text-decoration: none;

					&:hover {
						// Hover noch nicht final festgelegt, dies ist nur ein dummy von mir
						opacity: 0.8;
					}
				}

				i {
					font-size: 1.6rem;
					margin-left: 0.2em;
				}
			}
			.leibniz-logo-wrapper {
				grid-area: leibniz-logo;
				width: 150px;
				max-width: 100%;
				margin-bottom: 30px;

				img {
					width: 100%;
				}
			}
			nav.footer {
				grid-area: footer-nav;
			}

			@include xl {
				grid-template-areas:
					"contact-mannheim contact-koeln"
					"leibniz-logo social-media"
					"leibniz-logo footer-nav";

				gap: 0 2rem;

				.social-media-icons,
				.leibniz-logo-wrapper,
				nav.footer {
					justify-self: start;
				}

				.social-media-icons {
					a {
						padding-bottom: 0;
						padding-top: 0;

						&:first-of-type {
							padding-left: 0;
						}
					}
				}

				.leibniz-logo-wrapper {
					width: 200px;
				}

				.contact-info {
					margin-bottom: 2rem;
				}
			}

			@include md {
				grid-template-areas:
					"contact-mannheim"
					"contact-koeln"
					"leibniz-logo"
					"social-media"
					"footer-nav";

				.contact-info {
					margin-bottom: 4rem;
				}

				.leibniz-logo-wrapper {
					margin-bottom: 3rem;
				}

				.social-media-icons {
					display: flex;
					gap: 0.5rem;

					a {
						padding: 0;

						i {
							margin: 0;
						}
					}
				}

				nav.footer {
					display: flex;
					flex-direction: column;
					gap: 0.5em;

					a {
						width: max-content;
						
						&:not(:first-of-type) {
							margin-left: 0;
						}
					}
				}
			}
		}
	}
}
