.tabs {
	[role="tablist"] {
		--gs--button--color: #{gs-color(gs-berry)};
		--gs--button--hover-color: #fff;
		--gs--button--background-color: #{gs-color(gs-berry--14)};
		--gs--button--hover-background-color: #{gs-color(gs-berry)};

		background-color: gs-color(gs-berry--14);
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 1rem 1.5rem;
		padding: 1.3rem;
		border-top-right-radius: gs-var(default-border-radius);
		border-top-left-radius: gs-var(default-border-radius);
		margin-bottom: gs-space(third);

        button,
   		a {
			cursor: pointer;

			&:after {
				//Pfeil in Button ausblenden
				display: none;
			}

			/**
       * Ich habe &[aria-selected="true"] im button style ergänzt damit ein button mit dem Atteribut die Hover/Active Styles angewendet bekommt. 
       * Meiner Meinung nach ist es etwas eleganter dem button zu sagen unter welchen umständen er in den hover/active Style wechslen soll als hier nochmal alles zu überschreiben
       *
       * @author Julian Kohl
       *
       */
		}
	}

	[role="tabpanel"] {
		background-color: gs-color(gs-default-grey);
		border-bottom-right-radius: gs-var(default-border-radius);
		border-bottom-left-radius: gs-var(default-border-radius);

		&:not([hidden]) {
			padding: 2.5rem 4.5rem;
		}
	}
}
