.gs_mm_toggle_button {
  display: none;
  background: transparent;
  border: none;
  color: gs-color(gs-darkblue);
  position: absolute;
  top: 2.5rem;
  right: gs-space(side-space);
  font-size: 2rem;
  transition: all 0.2s ease-in-out;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1;

  &.active {
    transform: rotate(-90deg);
  }

  @include mobile {
    display: block;
  }

  &.microsite {
    top: unset !important;
    right: 2rem;
  }

  @include sm {
    top: 3.3rem;
  }

  @media (max-width: 400px) {
    top: 2.1rem;
  }
}

header.microsite-header {
  nav.gs_megamenu_nav {
    ul.gs_megamenu {
      >li {
        >a {
          font-size: 1.1rem;
        }
      }
    }
  }
}

nav.gs_megamenu_nav {
  .overview-and-close-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;

    a.gesis-button--default {
      text-decoration: none;
      @include gesis-button();
    }

    button.close-button {
      @include close-button();

      @include mobile {
        display: none;
      }
    }
  }

  ul.gs_megamenu {
    list-style: none;
    padding-left: 0;
    display: flex;
    gap: 3em;
    margin-bottom: 0;

    // Menü erste ebene (Angebot, Forschung, Insitut)
    >li {
      >a {
        font-size: 1.3rem;
        color: gs-color(gs-darkblue);
        text-decoration: none;
        position: relative;
        text-transform: uppercase;
        font-weight: bold;
        font-family: $headline-font;
        display: block;
        z-index: 0;
        letter-spacing: 0.06em;

        &[aria-expanded="true"]+div.menu-box {
          display: block;
          z-index: 60;
        }

        @include mobile {
          font-size: 2rem;
          padding: 1.2rem 2.8rem;
        }
      }

      &.gs_active>a,
      >a:hover,
      >a[aria-expanded="true"] {
        color: #fff;

        /**
        * Menuitems hatten vorhher mal ein padding und haben dann eine Hintergundfarbe beim hovern bekommen. 
        * Problem mit dieser Lösung ist der Padding. Der sorgt dafür, dass das letze Menüitem visuell nicht korrekt rechts an der Content-Kante lag.
        * Damit ich keine Negativen Margins nutzen muss habe ich die fäsche im Hintergrund stadtdessen mit dem Pseudoelement gebaut welches absolute positioned ist.
        * 
        * Falls jemand noch eine elegantere Lösung für das Problem findet kann man das auch gerne nochmal ändern.
        *
        * @author Julian Kohl (propeller GmbH)
        *
        */
        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 50%;
          top: 50%;
          width: calc(100% + 2em);
          height: calc(100% + 0.5em);
          transform: translate(-50%, -50%);
          background-color: gs-color(gs-pink);
          border-radius: 99999px;
          z-index: -1;

          @include mobile {
            display: none;
          }
        }

        @include mobile {
          color: gs-color(gs-pink);
        }
      }
    }

    // end erste ebene

    // Zweite Menüebene + Menübox
    >li {
      .menu-box {
        display: none;
        width: 100%;
        position: absolute;
        left: 0;
        top: 100%;
        background-color: #fff;
        border-top: 2px solid gs-color(gs-accent-grey);
        z-index: 1; // z-index 1 so prevent search from beeing on top of the open menu

        .menu-box--inner {
          @include max-width-content;
          padding-top: 30px;
          padding-bottom: 30px;

          >ul {
            // Liste 2. Menüebene
            list-style: none;
            padding-left: 0;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
            gap: 50px 20px;

            >li {
              position: relative;

              >a {
                text-decoration: none;
                font-weight: bold;
                color: gs-color(gs-darkblue);
                @include arrow-right-before($arrow-font-size: 0.8em, $arrow-color: gs-color(gs-pink));

                @include mobile {
                  background: gs-color(gs-blue--8);
                  padding: 1.2rem 1rem;
                  // 1rem because the trigger has 1rem right and 1 more to have space between link an trigger
                  padding-right: calc(2rem + #{gs-var(mobile-menu-submenu-trigger-size)});
                }
              }

              @include mobile {
                padding: 0;

                &:not(:last-child) {
                  border-bottom: solid 1px gs-color(gs-darkblue);
                }
              }
            }

            @include mobile {
              grid-template-columns: 1fr;
              gap: 0;
            }
          }
        }

        @include mobile {
          position: static;
          border: none;
        }
      }
    }

    // Dritte Menüebene

    >li {
      .menu-box ul {
        >li {
          >ul {
            list-style: none;
            padding-left: 0;
            margin-top: 15px;

            >li {
              a {
                border-top: 1px solid gs-color(gs-darkblue);
                padding: 0.3em 0.2em;
                text-decoration: none;
                color: #000;
                @include arrow-right-before($arrow-font-size: 0.8em, $arrow-color: gs-color(gs-pink));

                &:hover {
                  background-color: gs-color(gs-default-grey);
                }

                @include mobile {
                  padding: 0.5rem 1rem;
                  padding-left: 2rem;
                }
              }
            }

            @include mobile {
              background: white;
              margin: 0;
              display: none;

              &.active {
                display: block;
              }
            }
          }
        }
      }
    }

    @include mobile {
      background: white;
      padding: 0;
      margin: 0;
      display: block;
    }

    &.microsite {
      margin-top: 2rem;
    }
  }

  @include mobile {
    position: absolute;
    //top: 140px;
    left: 0;
    right: 0;
    height: 100%;
    background: gs-color(gs-darkblue-transparent);
    z-index: 1000;
    padding: 0;
    margin: 0;
    display: none;

    &.active {
      display: block;
    }
  }

  .gs_topnav {
    background: gs-color(gs-default-grey);
    padding: 1.2rem 2.8rem;

    a {
      color: gs-color(gs-darkblue) !important;
    }
  }

  .mobile-only {
    padding: 0 !important;
  }
}

.microsite-header {
  nav.gs_megamenu_nav {
    ul.gs_megamenu {
      flex-wrap: wrap;
      gap: 1em 3em;
    }
  }
}

.microsite-menu {
  @include mobile {
    min-height: 90px;

    .gs_megamenu_nav {
      margin-top: 4rem;
    }
  }
}

.menu-trigger {
  border: solid 3px gs-color(gs-light);
  background: white;
  border-radius: 100px;
  width: gs-var(mobile-menu-submenu-trigger-size);
  height: gs-var(mobile-menu-submenu-trigger-size);
  text-align: center;
  position: absolute;
  top: 2rem;
  right: 1rem;
  justify-content: center;
  transform: translateY(-50%);
  transition: all 0.2s ease-in-out;

  @include mobile {
    display: flex !important;
  }

  @include arrow-right-after(inherit, gs-color(gs-pink), 0);

  &.active {
    transform: translateY(-50%) rotate(90deg);
  }
}


nav.gs_megamenu_nav_vert {
  list-style: none;
  width: 100%;
  //font-size: 16px;


  >ul {
    list-style: none;
    padding-left: 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: 0;

    >li {
      position: relative;
      padding: 0;

      &:not(:last-child) {
        border-bottom: solid 1px gs-color(gs-darkblue);
      }

      >a {
        text-decoration: none;
        font-weight: bold;
        color: gs-color(gs-darkblue);
        @include arrow-right-before($arrow-font-size: 0.8em, $arrow-color: gs-color(gs-pink));
        background: gs-color(gs-blue--8);
        padding: 1.2rem 1rem;
        // 1rem because the trigger has 1rem right and 1 more to have space between link an trigger
        padding-right: calc(2rem + #{gs-var(mobile-menu-submenu-trigger-size)});
        box-shadow: none;
      }

      >ul {
        list-style: none;
        padding-left: 0;
        margin-top: 15px;
        background: white;
        margin: 0;
        display: none;

        &.active {
          display: block;
        }

        >li {
          position: relative;
          padding: 0;

          >a {
            border-top: 1px solid gs-color(gs-darkblue);
            text-decoration: none;
            color: #000;
            @include arrow-right-before($arrow-font-size: 0.8em, $arrow-color: gs-color(gs-pink));
            //background: gs-color(gs-petrol--14);
            //&:hover {
            //background-color: gs-color(gs-default-grey);
            //}
            padding: 1.2rem 1rem;
            padding-left: 2rem;
            padding-right: calc(2rem + #{gs-var(mobile-menu-submenu-trigger-size)});
            box-shadow: none;

            &:hover {
              background-color: gs-color(gs-default-grey);
            }
          }

          >ul {
            list-style: none;
            padding-left: 0;
            margin-top: 15px;
            background: white;
            margin: 0;
            display: none;

            &.active {
              display: block;
            }

            >li {
              >a {
                border-top: 1px solid gs-color(gs-darkblue);
                text-decoration: none;
                color: #000;
                @include arrow-right-before($arrow-font-size: 0.8em, $arrow-color: gs-color(gs-pink));

                //background: gs-color(gs-default-grey);
                &:hover {
                  background-color: gs-color(gs-default-grey);
                }

                padding: 1.2rem 1rem;
                padding-left: 3rem;
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }
}
