.content-section {
	.content-section--inner {
		position: relative;
		padding-top: gs-space(section-space-top);
		padding-bottom: gs-space(section-space-bottom);
		@include max-width-content;
	}

  &.has-bg-img {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    color: #fff;
    --gs--headline--color: #fff;
    --gs--link-underline--color: #fff;
  }

  


      &.frame-space-inner-before-small {
        .content-section--inner {
          padding-top: calc(#{gs-space(section-space-top)} / 2);
        }
      }
      &.frame-space-inner-after-small {
        .content-section--inner {
          padding-bottom: calc(#{gs-space(section-space-bottom)} / 2);
        }
      }
      
      &.frame-space-inner-before-none {
        .content-section--inner {
          padding-top: 0;
        }
      }
      &.frame-space-inner-after-none {
        .content-section--inner {
          padding-bottom: 0;
        }
      }
}
