$icomoon-font-family: "gesis-icons" !default;
$icomoon-font-path: "fonts" !default;

$gesis-icon-appbardelete: unquote('"\\e932"');
$gesis-icon-gs-contact: unquote('"\\e933"');
$gesis-icon-gs-lang: unquote('"\\e934"');
$gesis-icon-gs-help: unquote('"\\e935"');
$gesis-icon-gs-rss: unquote('"\\e936"');
$gesis-icon-gs-phone: unquote('"\\e937"');
$gesis-icon-gs-vcard: unquote('"\\e938"');
$gesis-icon-gs-cv: unquote('"\\e939"');
$gesis-icon-gs-lockopen: unquote('"\\e93a"');
$gesis-icon-gs-refresh: unquote('"\\e93b"');
$gesis-icon-gs-newwindow: unquote('"\\e93c"');
$gesis-icon-gs-pagemultiple: unquote('"\\e93d"');
$gesis-icon-gs-link: unquote('"\\e93e"');
$gesis-icon-gs-lockclosed: unquote('"\\e93f"');
$gesis-icon-gs-information: unquote('"\\e940"');
$gesis-icon-gs-download: unquote('"\\e941"');
$gesis-icon-gs-graphline: unquote('"\\e942"');
$gesis-icon-gs-globewire: unquote('"\\e943"');
$gesis-icon-gs-filter: unquote('"\\e944"');
$gesis-icon-gs-close: unquote('"\\e945"');
$gesis-icon-gs-publication: unquote('"\\e946"');
$gesis-icon-gs-arrowup: unquote('"\\e947"');
$gesis-icon-gs-arrowdown: unquote('"\\e948"');
$gesis-icon-gs-tools: unquote('"\\e949"');
$gesis-icon-gs-questions-variables: unquote('"\\e94a"');
$gesis-icon-gs-library: unquote('"\\e94b"');
$gesis-icon-box-arrow-in: unquote('"\\e931"');
$gesis-icon-no-costs: unquote('"\\e92e"');
$gesis-icon-pin-angle-fill: unquote('"\\e92f"');
$gesis-icon-target: unquote('"\\e930"');
$gesis-icon-question-circle: unquote('"\\e92a"');
$gesis-icon-target-circle: unquote('"\\e92b"');
$gesis-icon-pin-circle: unquote('"\\e92c"');
$gesis-icon-no-costs-circle: unquote('"\\e92d"');
$gesis-icon-clipboard-check-circle: unquote('"\\e924"');
$gesis-icon-eye-circle: unquote('"\\e925"');
$gesis-icon-faq-circle: unquote('"\\e926"');
$gesis-icon-people-circle: unquote('"\\e927"');
$gesis-icon-question-catalog-circle: unquote('"\\e928"');
$gesis-icon-question-sheet-circle: unquote('"\\e929"');
$gesis-icon-grid-view: unquote('"\\e922"');
$gesis-icon-list-view: unquote('"\\e923"');
$gesis-icon-rss: unquote('"\\e921"');
$gesis-icon-checkmark: unquote('"\\e920"');
$gesis-icon-search: unquote('"\\e91f"');
$gesis-icon-close-x: unquote('"\\e91e"');
$gesis-icon-vcard-circle: unquote('"\\e900"');
$gesis-icon-person-circle: unquote('"\\e901"');
$gesis-icon-euro-circle: unquote('"\\e902"');
$gesis-icon-mail-circle: unquote('"\\e903"');
$gesis-icon-fax-circle: unquote('"\\e904"');
$gesis-icon-phone-circle: unquote('"\\e905"');
$gesis-icon-location-circle: unquote('"\\e906"');
$gesis-icon-calendar-arr-down: unquote('"\\e907"');
$gesis-icon-blogger: unquote('"\\e908"');
$gesis-icon-youtube: unquote('"\\e909"');
$gesis-icon-twitter-x: unquote('"\\e90a"');
$gesis-icon-mastodon: unquote('"\\e90b"');
$gesis-icon-linkedin: unquote('"\\e90c"');
$gesis-icon-instagram: unquote('"\\e90d"');
$gesis-icon-facebook: unquote('"\\e90e"');
$gesis-icon-question: unquote('"\\e90f"');
$gesis-icon-person-fill: unquote('"\\e910"');
$gesis-icon-currency-euro: unquote('"\\e911"');
$gesis-icon-files: unquote('"\\e912"');
$gesis-icon-database: unquote('"\\e913"');
$gesis-icon-ui-checks: unquote('"\\e914"');
$gesis-icon-bookmark-fill: unquote('"\\e915"');
$gesis-icon-download: unquote('"\\e916"');
$gesis-icon-chat-left-quote-fill: unquote('"\\e917"');
$gesis-icon-caret-down-fill: unquote('"\\e918"');
$gesis-icon-caret-up-fill: unquote('"\\e919"');
$gesis-icon-arrow-down-short: unquote('"\\e91a"');
$gesis-icon-arrow-up-short: unquote('"\\e91b"');
$gesis-icon-arrow-left-short: unquote('"\\e91c"');
$gesis-icon-arrow-right-short: unquote('"\\e91d"');

