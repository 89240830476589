@import "./breakpoint-mixins";

@mixin side-space($use-margin-for-space: false) {
	// Hier könnte man auch zwei mixins machen und auf den Parameter verzichten
	padding-left: gs-space(side-space);
	padding-right: gs-space(side-space);

	@if $use-margin-for-space {
		padding-left: gs-space(side-space);
		padding-right: gs-space(side-space);
	}
}

@mixin max-width-content {
	max-width: $max-content-width;
	margin-left: auto;
	margin-right: auto;
	@include side-space;
}

@mixin underline-link {
	text-decoration: none;
	box-shadow: inset 0 -1px 0 0 gs-var(link-underline--color);

	&:hover {
		box-shadow: inset 0 gs-var(link-underline--hover-height) 0 0 gs-var(link-underline--hover-color);
	}
}

@mixin arrow-right-before($arrow-font-size: inherit, $arrow-color: inherit, $space-between: 10px, $flex-align: baseline) {
	display: flex;
	align-items: $flex-align;

	&:before {
		content: "\e91d";
		margin-right: $space-between;
		font-size: $arrow-font-size;
		color: $arrow-color;
		font-family: $gesis-icons-font;
		font-style: normal;
		font-weight: normal !important;
		font-variant: normal;
		text-transform: none;
		line-height: $arrow-font-size;
	}
}

@mixin arrow-right-after($arrow-font-size: inherit, $arrow-color: inherit, $space-between: 0.5em, $flex-align: center) {
	display: flex;
	align-items: $flex-align;

	&:after {
		content: "\e91d";
		margin-left: $space-between;
		font-size: $arrow-font-size;
		color: $arrow-color;
		font-family: $gesis-icons-font;
		font-style: normal;
		font-weight: normal !important;
		font-variant: normal;
		text-transform: none;
		line-height: $arrow-font-size;
	}
}

@mixin arrow-down-after($arrow-font-size: inherit, $arrow-color: inherit, $space-between: 0.5em, $flex-align: center) {
	position: relative;

	&:after {
		content: $gesis-icon-arrow-down-short;
		position: absolute;
		top: 50%;
		right: 1rem;
		transform: translateY(-50%);
		border: none;
		margin-left: $space-between;
		font-size: $arrow-font-size;
		color: $arrow-color !important;
		font-family: $gesis-icons-font;
		font-style: normal;
		font-weight: normal !important;
		font-variant: normal;
		text-transform: none;
		line-height: $arrow-font-size;
	}
}

@mixin gesis-button($button-text-size: 1rem, $arrow-font-size: 1em, $space-between: 0.5em) {
	@include arrow-right-after($arrow-font-size: $arrow-font-size, $arrow-color: gs-var(button--color), $space-between: $space-between);
	font-size: $button-text-size;
	font-weight: bold;
	background-color: gs-var(button--background-color);
	border-radius: 999999px;
	border: 3px solid gs-var(button--color);
	color: gs-var(button--color);
	padding: 0.7rem 1.7rem 0.6rem;

	&:hover,
	&[aria-selected="true"] {
		background-color: gs-var(button--hover-background-color);
		color: gs-var(button--hover-color);

		&:after {
			// Change color of arr in button
			color: gs-var(button--hover-color);
		}
	}

	@include sm {
		padding: 0.5rem 1.1rem 0.4rem;
	}
}

@mixin close-button($button-size: 45px, $button-theme-color: gs-color(gs-dark-grey), $button-hover-x-color: #fff, $button-x-size: inherit) {
	border-radius: calc(2 * #{$button-size});
	width: $button-size;
	height: $button-size;
	line-height: $button-size;
	background-color: #fff;
	border: 3px solid $button-theme-color;
	text-indent: -999999px;
	position: relative;

	&:after {
		content: "\e91e";
		display: grid;
		place-content: center;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		color: $button-theme-color;
		font-family: $gesis-icons-font;
		text-indent: initial;
		font-size: $button-x-size;
	}

	&:hover {
		background-color: $button-theme-color;

		&:after {
			color: $button-hover-x-color;
		}
	}
}

@mixin round-icon-before($icon-size: 1.3rem, $space-between: 0.5rem, $icon-color: gs-color(gs-darkblue), $icon: "" /* can be used to set icon without class on element*/) {
	display: inline-flex;
	align-items: center;

	&:before {
		font-family: $gesis-icons-font;
		color: $icon-color;
		margin-right: $space-between;
		line-height: 1;
		font-size: $icon-size;
	}

	&.icon-tel:before {
		content: $gesis-icon-phone-circle;
	}
	&.icon-mail:before {
		content: $gesis-icon-mail-circle;
	}
	&.icon-vcard:before {
		content: $gesis-icon-vcard-circle;
	}
	&.icon-fax:before {
		content: $gesis-icon-fax-circle;
	}
	&.icon-location:before {
		content: $gesis-icon-location-circle;
	}

	@if $icon != "" {
		&::before {
			content: $icon;
		}
	}
}

@mixin pagination-dot($dot-size: 2rem) {
	aspect-ratio: 1;
	display: inline-block;
	border-radius: 1000px !important;
	width: $dot-size;
	line-height: $dot-size;
	font-size: 1rem;
	padding: 0;
	margin: 0;
	text-align: center;
	background: gs-color(gs-dark-grey);
	color: white;
	border: none;
	font-weight: bold;

	// Remove default link underline
	box-shadow: none;
}


@mixin mobile {
	@media (max-width: $mobile-width) { @content; }
}