nav.gs_rootline {
	ol {
		list-style: none;
		display: flex;
		gap: 1rem;

		li:not(:last-of-type) {
			position: relative;

			&::after {
				content: ">";
				position: absolute;
				inset: unset;
				width: auto;
				height: auto;
				background: transparent !important;
				top: 0;
				font-weight: 100;
				right: -1rem;
				transform: translateX(-50%);
			}
		}

		a {
			--gs--link-underline--color: #fff;
			--gs--link-underline--hover-color: #{rgba(#fff, 0.5)};

			text-decoration: none;
			color: inherit;
			@include underline-link;

			// Remove default link underline
			&:not(:hover) {
				box-shadow: none;
			}
		}
	}
}
