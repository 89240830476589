// Overwrite / Reset Browser default stylings

html,
body {
	width: 100%;
}

html {
	font-family: $default-font;
	font-size: $root-font-size;
	-webkit-text-size-adjust: 100%;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: grayscale;

	@include sm {
		font-size: 1rem;
		-webkit-hyphens: auto;
		-ms-hyphens: auto;
		hyphens: auto;
	}
}

img {
	max-width: 100%;
	height: auto;
}

.desktop-only {
	@include mobile {
		display: none;
	}
}

.mobile-only {
	display: none !important;

	@include mobile {
		display: block !important;
	}
}
