// Imported in _variables.scss
$base-space: 1rem;

$gs-spaces: (
	side-space: 8.5rem,
	section-space-top: 4.8rem,
	section-space-top--bigger: 7.8rem,
	section-space-bottom: 4.8rem,
	section-space-bottom--bigger: 7.2rem,
	base: $base-space,
	third: calc(var(--gs--space--base) / 3),
	half: calc(var(--gs--space--base) / 2),
	one-and-half: calc(var(--gs--space--base) * 1.5),
	double: calc(var(--gs--space--base) * 2),
	tripple: calc(var(--gs--space--base) * 3),
    grid-gutter-width: 2.6rem,
	grid-gutter-height: 2.6rem,

	// Abstände die man im Typo3 wählen kann
	none: 0,
  	extra-small: $base-space,
  	small: calc(var(--gs--space--base) * 2),
  	medium: calc(var(--gs--space--base) * 3),
  	large: calc(var(--gs--space--base) * 4),
  	extra-large: calc(var(--gs--space--base) * 5),
);

:root {
	@each $variableName, $variableValue in $gs-spaces {
		--gs--space--#{$variableName}: #{$variableValue};
	}

	@media (max-width: #{$desktop-medium}) {
		--gs--space--side-space: 4rem;
	}

	@media (max-width: #{$lg}) {
		--gs--space--side-space: 1.5rem;
	}

	@media (max-width: #{$sm}) {
		--gs--space--side-space: 1.2rem;
	}
}

@function gs-space($variableName) {
	@return var(--gs--space--#{$variableName});
}

@each $spaceName, $spaceValue in $gs-spaces {
  .frame-space-before-#{$spaceName} {
    margin-top: $spaceValue;
  }
  .frame-space-after-#{$spaceName} {
    margin-bottom: $spaceValue;
  }
}
