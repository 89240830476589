.gesis-contentbox {
	.gesis-contentbox--header {
		background-color: gs-color(gs-berry--14);
		border-top-left-radius: gs-var(default-border-radius);
		border-top-right-radius: gs-var(default-border-radius);
		padding: 0.5rem 3rem;
		border-bottom: 3px solid #fff;

		> * {
			//Remove margin of elements wrapping box header text eg. typo3 creates an <header> arround Headlines
			margin: 0 auto;
		}

		p,
		h1,
		h2,
		h3,
		h4 {
			margin: 0 auto;
			text-align: center;
			font-family: $headline-font;
			color: gs-color(gs-berry);
			font-weight: 500;
			font-size: gs-font-size(heading--tertiary);
		}
	}

	.gesis-contentbox--content {
		border-bottom-left-radius: gs-var(default-border-radius);
		border-bottom-right-radius: gs-var(default-border-radius);
		background-color: gs-color(gs-default-grey);
		padding: 1.8rem 3rem;

		/**
		* Remove margin from last and first child to prevent unwanted spacings in boxcontent beginning and end
		*
		* @author Julian Kohl
		*
		*/

		> :last-child {
			margin-bottom: 0;
		}

		> :first-child {
			margin-top: 0;
		}

		@include xl {
			padding: 2rem 1.5rem;
		}
	}

	&.has-icon {
		.gesis-contentbox--header > * {
			position: relative;
			padding-right: 2rem;
			// Added padding left to keep Box Headline centered - Julian Kohl
			padding-left: 2rem;

			&:after {
				font-family: $gesis-icons-font;
				display: block;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				color: gs-color(gs-berry);
				font-size: 1.5rem;
			}
		}

		/**
		* So würde man auch für alle anderen Icons vorgehen die im Header auswählbar sein sollen.
		*
		* @author Julian Kohl
		*
		*/

		&.rss {
			.gesis-contentbox--header > *:after {
				content: "\e921";
			}
		}
	}

	&.has-rss-link {
		.gesis-contentbox--header {
			position: relative;

			> p {
				padding-right: 2rem;
				// Added padding left to keep Box Headline centered - Julian Kohl
				padding-left: 2rem;
			}

			> a {
				display: block;
				position: absolute;
				right: 3rem;
				top: 50%;
				transform: translateY(-50%);
				line-height: 1;

				// Remove default link underline
				box-shadow: none;

				i {
					color: gs-color(gs-berry);
					font-size: 1.5rem;
				}

				&:hover {
					opacity: 0.8;
				}
			}
		}
	}

	&.blue-header {
		.gesis-contentbox--header {
			background-color: gs-color(gs-lightblue--14);

			> * {
				color: gs-color(gs-darkblue);

				// Change color of icon if it exists
				&:after {
					color: gs-color(gs-darkblue);
				}
			}
		}
	}

	&.events-calendar-box {
		.gesis-contentbox--header {
			> a {
				right: 1.5rem;

				i {
					color: gs-color(gs-darkblue);
				}
			}
		}
		.gesis-contentbox--content {
			padding-left: 1.5rem;
			padding-right: 1.5rem;
		}
	}
}
