//Iconfont
@import "../font/GesisIcons/style";

// Brandon Grotesque
@font-face {
	font-family: "Brandon Grotesque";
	src: url("#{$fonts-path}/BrandonGrotesque/BrandonGrotesque-Medium.woff") format("woff"), 
	url("#{$fonts-path}/BrandonGrotesque/BrandonGrotesque-Medium.woff2") format("woff2");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Brandon Grotesque";
	src: url("#{$fonts-path}/BrandonGrotesque/BrandonGrotesque-Bold.woff") format("woff"), 
	url("#{$fonts-path}/BrandonGrotesque/BrandonGrotesque-Bold.woff2") format("woff2");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

// Source Sans Pro
@font-face {
	font-family: "Source Sans Pro";
	src: url("#{$fonts-path}/SourceSansPro/SourceSansPro-Regular.woff") format("woff"), 
	url("#{$fonts-path}/SourceSansPro/SourceSansPro-Regular.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Source Sans Pro";
	src: url("#{$fonts-path}/SourceSansPro/SourceSansPro-Italic.woff") format("woff"), 
	url("#{$fonts-path}/SourceSansPro/SourceSansPro-Italic.woff2") format("woff2");
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}
@font-face {
	font-family: "Source Sans Pro";
	src: url("#{$fonts-path}/SourceSansPro/SourceSansPro-Bold.woff") format("woff"), 
	url("#{$fonts-path}/SourceSansPro/SourceSansPro-Bold.woff2") format("woff2");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Source Sans Pro";
	src: url("#{$fonts-path}/SourceSansPro/SourceSansPro-Bold-Italic.woff") format("woff"), 
	url("#{$fonts-path}/SourceSansPro/SourceSansPro-Bold-Italic.woff2") format("woff2");
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

// Cabin (source: https://fonts.google.com/specimen/Cabin)
@font-face {
	font-family: "Cabin";
	src: url("#{$fonts-path}/Cabin/Cabin-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Cabin";
	src: url("#{$fonts-path}/Cabin/Cabin-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Cabin";
	src: url("#{$fonts-path}/Cabin/Cabin-SemiBold.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Cabin";
	src: url("#{$fonts-path}/Cabin/Cabin-Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}
