nav {
	&.gs_topnav {
		ul {
			list-style: none;
			display: flex;
			gap: 1em;

			li {
				a {
					color: gs-color(gs-dark-grey);
					text-decoration: none;
					font-size: 1.1rem;

					&:hover {
						color: gs-color(gs-pink);
					}

					&[hreflang] {
						display: flex;
						align-items: baseline;

						img {
							width: auto;
							height: 0.7em;
							border: 1px solid gs-color(gs-dark-grey);
							margin-right: 0.2em;
						}
					}
				}
			}
		}
	}

	&.footer {
		a {
			color: inherit;
			text-decoration: none;
			@include underline-link;

			&:not(:first-of-type) {
				margin-left: 1em;
			}
		}
	}
}
