.gs-abstract {
    padding: 1.5rem 1.5rem 0;
    margin: 1.5rem 0;
    border-color: var(--gs--color--gs-darkblue);
    border-radius: var(--bs-border-radius-xl);
    border-style: solid;
    border-width: 2px;
}

.gs-interview-question {
    color: var(--gs--color--gs-darkblue);
    
    &-indent {
        color: var(--gs--color--gs-darkblue);
    } 
}
.gs-interview-answer-name {
    color: var(--gs--color--gs-darkblue);
  }


.gs-heading-inline-color,
.gs-sourcecode-zchn {
    color: var(--gs--color--gs-darkblue);
}
.gs-heading-inline-italic {
    font-style: italic;
    font-weight: bold;
}
.gs-heading-inline-bold {
    font-weight: bold;
}