.gs-inline-box {
    background-color: var(--gs--color--gs-darkblue--14);
    border-radius: var(--bs-border-radius-xl);
    padding: 1rem;
    margin: 1rem 0;

    &--lightblue {
        background-color: var(--gs--color--gs-lightblue--14);
        border-radius: var(--bs-border-radius-xl);
        padding: 1rem;
        margin: 1rem 0;
        }

    &--berry {
        background-color: var(--gs--color--gs-berry--14);
        border-radius: var(--bs-border-radius-xl);
        padding: 1rem;
        margin: 1rem 0;
    }

    &--turquoise {
        background-color: var(--gs--color--gs-turquoise--14);
        border-radius: var(--bs-border-radius-xl);
        padding: 1rem;
        margin: 1rem 0;
        }

    &--orange {
        background-color: var(--gs--color--gs-orange--14);
        border-radius: var(--bs-border-radius-xl);
        padding: 1rem;
        margin: 1rem 0;
        }

    &--yellow {
        background-color: var(--gs--color--gs-yellow--14);
        border-radius: var(--bs-border-radius-xl);
        padding: 1rem;
        margin: 1rem 0;
        }

    &--petrol {
        background-color: var(--gs--color--gs-petrol--14);
        border-radius: var(--bs-border-radius-xl);
        padding: 1rem;
        margin: 1rem 0;
        }

    &--lightpink {
        background-color: var(--gs--color--gs-lightpink--14);
        border-radius: var(--bs-border-radius-xl);
        padding: 1rem;
        margin: 1rem 0;
        }
    
    &--olive {
        background-color: var(--gs--color--gs-olive--14);
        border-radius: var(--bs-border-radius-xl);
        padding: 1rem;
        margin: 1rem 0;
        }

    &--rust {
        background-color: var(--gs--color--gs-rust--14);
        border-radius: var(--bs-border-radius-xl);
        padding: 1rem;
        margin: 1rem 0;
        }
}