.pagination {
	text-align: center;
	display: block;
	margin-bottom: 0;
	margin-top: 1.8rem;

	$size: 2rem;
	.page-item {
		display: inline-block;

		a, >span.has-more-pages {
			@include pagination-dot;

			& > span {
				vertical-align: middle;
			}
		}

		&:not(:last-of-type) {
			margin-right: 0.5rem;
		}

		&.active {
			a {
				background: gs-color(gs-berry);
			}
		}
	}
}
