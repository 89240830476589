main {
	ul,
	ol {
		padding-left: 1rem;
	}

	ul {
		&.gesis-link-list--with-bg {
			list-style: none;
			padding-left: 0;

			li {
				background-color: #fff;
				padding: 1.3rem 1.5rem;
				border-bottom: 2px solid gs-color(gs-accent-grey);
			}
		}

		&.gesis-link-list {
			list-style: none;
			padding-left: 0;

			li {
				a {
					@include arrow-right-before($arrow-font-size: 1em, $arrow-color: gs-var(link-underline--color));
					display: inline-flex;

					// Remove default link underline
					box-shadow: none;

					&:hover {
						color: gs-var(link-with-icon--hover-color);

						&:before {
							color: gs-var(link-with-icon--hover-color);
						}
					}
				}
			}
		}
	}
}
